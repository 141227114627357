import React ,{useEffect,useState}from 'react';
import {ResSlider} from '../component/ResSlider';
import {Ourproduct} from '../component/Ourproduct';
import loadjs from 'loadjs';
import { Aboutus } from '../component/Aboutus';
import { WhyChoose } from '../component/WhyChoose';
import { Testimonial } from '../component/Testimonial';
import { HomeContact } from '../component/HomeContact';
import { NewsBlog } from '../component/NewsBlog';
import { HomeAddress } from '../component/HomeAddress';
import { useSelector,useDispatch } from "react-redux";
import { getAdvertisement, getAdvertisementar, listHomeAbout, listHomeAboutar, listHomeNews, listHomeNewsar, listHomeProduct, listHomeProductar, listHomeTestimonial, listHomeTestimonialar, listHomeWhychooseUs, listHomeWhychooseUsar, listHomecontacts, listHomecontactsar } from '../service/apiService';

import 'react-modal-overlay/dist/index.css'
import { config } from '../config/rest';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Loading from 'react-fullscreen-loading';


const Home = () => {
  const langData=useSelector(state=>state.cart);
  const [homeProduct,setHomeProduct]=useState([]);
  const [homeAbt,setHomeAbt]=useState([]);
  const [homeWhyChooseus,sethomeWhyChooseus]=useState([]);
  const [homeTestimonial,sethomeTestimonial]=useState([]);
  const [homeNews,sethomeNews]=useState([]);
  const [homeContact,sethomeContact]=useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [adVert,setAdvert]=useState('');
  const [advertImage,setAdvertImage]=useState('');
  const [advertTitle,setAdverttitle]=useState('');
  const [loading,setLoading]=useState(true);
  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
    loadjs('assets/js/main.js', () => {});
    const rootHtml = document.getElementById("root-html");
    if (rootHtml) {
     rootHtml.setAttribute("dir", langData.lang==='ar' ? "rtl" : "ltr");
     rootHtml.setAttribute("lang", langData.lang ? "rtl" : "ltr");
   }
   
   getHomeProduct();
   getHomeAdbout();
   getHomewhyChooseUs();
   getHomeTestimonial();
   getHomeNews();
   getHomeContact();
   getAdvert();
  },[langData]);

  const getAdvert=async()=>{
    setLoading(true);
    const Advertisemnt=langData.lang==='ar'?await getAdvertisementar() : await getAdvertisement();
    // consolee.log('abcdefgh',Advertisemnt.data);
    //setHomeProduct(homeProduct.data.data); 
    setAdvert(Advertisemnt.data.data[0] && Advertisemnt.data.data[0]);
    setAdvertImage(Advertisemnt.data.data[0] &&Advertisemnt.data.data[0].attributes.image.data.attributes.url)
    setAdverttitle(Advertisemnt.data.data[0] &&Advertisemnt.data.data[0].attributes.title);
    Advertisemnt.data.data[0] && Advertisemnt.data.data[0].attributes.isVisible=="True" ? setIsOpen(true):setIsOpen(false);
    setLoading(false);
  }

  const getHomeProduct=async()=>{
    setLoading(true);
    const homeProduct=langData.lang==='ar'?await listHomeProductar() : await listHomeProduct();
    setHomeProduct(homeProduct.data.data);
    setLoading(false);
  }
  const getHomeAdbout=async()=>{
    setLoading(true);
    const homeAbt=langData.lang==='ar'? await listHomeAboutar(): await listHomeAbout();
    console.log(homeAbt);
    setHomeAbt(homeAbt.data.data);
    setLoading(false);
    
  }
  const getHomewhyChooseUs=async()=>{
    setLoading(true);
    const homeWhyChooseus=langData.lang==='ar'?await listHomeWhychooseUsar() : await listHomeWhychooseUs();
    sethomeWhyChooseus(homeWhyChooseus.data.data);
    setLoading(false);
  }
  const getHomeTestimonial=async()=>{
    setLoading(true);
    const homeTestimonila=langData.lang==='ar'?await listHomeTestimonialar():await listHomeTestimonial();
    sethomeTestimonial(homeTestimonila.data.data);
    setLoading(false);
  }
  const getHomeNews=async()=>{
    setLoading(true);
    const homeTestimonila=langData.lang==='ar'?await listHomeNewsar() :await listHomeNews();
     console.log(homeTestimonila.data.data);
    sethomeNews(homeTestimonila.data.data);
    setLoading(false);
  }
  const getHomeContact=async()=>{
    setLoading(true);
    const homeContact=langData.lang==='ar'?await listHomecontactsar() :await listHomecontacts();
   console.log(homeContact.data.data);
    sethomeContact(homeContact.data.data);
    setLoading(false);
  }

  
  return (
    <>
   <Loading  loading ={loading} background="rgb(36 36 36 / 17%)" loaderColor="#000000" />

    <ResSlider/>
   {/* <div style={{marginTop:100}}>
      <Modal show={isOpen} closeModal={() => setIsOpen(false)}>
      <h4> here is hook modal data </h4>
       <div>
       <img src={config.IMG_URL+advertImage} className="rounded" alt="..."/>
       </div>
       
                      
      </Modal>
  </div>*/}

<Modal open={isOpen} onClose={()=>setIsOpen(false)} center>
        <h2>{advertTitle}</h2>
        <img src={config.IMG_URL+advertImage} className="rounded" alt="..."/>
      </Modal>

    <Ourproduct langData={langData} prdData={homeProduct  && homeProduct}/>
    
    <Aboutus langData={langData} abtData={homeAbt  && homeAbt} />
    <WhyChoose langData={langData} chooseUs={homeWhyChooseus}/>
    <Testimonial testMonial={homeTestimonial} langData={langData}/>
    <HomeContact langData={langData}/>
    <NewsBlog news={homeNews} langData={langData}/>
    <HomeAddress address={homeContact} langData={langData}/>
    </>
  )
}

export default Home