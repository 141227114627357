import React,{useEffect,useState} from 'react'
import { BreadCumb } from '../component/BreadCumb'
import { Content } from './Faq/Content'
import { faqCeoPage, faqCeoPagear, faqContactPage, faqContactPagear, faqPage, faqPagear, listHomecontacts } from '../service/apiService'
import { useSelector,useDispatch } from "react-redux";

export const Faq = () => {
  const langData=useSelector(state=>state.cart);

  const [faq,setfaq]=useState([]);
  const [faqCeo,setfaqceo]=useState([]);
  const [faqAdd,setfaqadd]=useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
    const rootHtml = document.getElementById("root-html");
    if (rootHtml) {
     rootHtml.setAttribute("dir", langData.lang==='ar' ? "rtl" : "ltr");
     rootHtml.setAttribute("lang", langData.lang ? "rtl" : "ltr");
   }
   
    getFaq();
    console.log(langData.lang);
  },[langData]);
  const getFaq=async()=>{
    const faq=langData.lang==='ar'?await faqPagear():await faqPage();
    const ceoDetails=langData.lang==='ar'? await faqCeoPagear() :await faqCeoPage();
    const homeContact=langData.lang==='ar'?await faqContactPagear() :await faqContactPage();
    setfaq(faq.data.data);
    setfaqceo(ceoDetails.data.data); 
    setfaqadd(homeContact.data.data);
   
  }
  return (
    <>
     <BreadCumb bgImage={"url('assets/img/banner/faq.jpeg')"} routeLink={"faq"} mainRoute={"Home"} 
    route={"Faq"} title={"FAQ"}/>
    <Content faqData={faq} ceoData={faqCeo} addData={faqAdd} langD={langData.lang}/>
    </>
  )
}
