export const Lang = {
    en: {
        //header
        menuen:'EN',
        menuar:'AR',
        menuHome:'HOME',
        menuAbout:'ABOUT US',
        submenuAbout:'ABOUT US',
        submenuAbtmsgCeo:'Message from COO',
        submentAbtTeams:'Team Structure',
        submenutAbtFinan:'Financials',
        homebread:'Home',
        prdAbtBtn:'About Us',
        prdProposalBtn:'Proposal Form',
        prdClaimBtn:'Claim Form',
        submenutAbtTender:'Tender',
        menuProduct:'OUR PRODUCTS',
        testinmonital:'TESTIMONIAL',
        submenutPrdprivate:'PRIVATE',
        submenutPrdcommercial:'COMMERCIAL',
        submenutPrdindustrial:'INDUSTRIAL',
        submenutPrdlibility:'LIABILITY',
        submenutPrdOthers:'OTHERS',
        menuOffice:'OUR OFFICE',
        submenuOff:'OUR OFFICE',
        submenuofGcc:'GCC LOCATION',
        submenuOfWwl:'WORLD WIDE LOCATIONS',
        submenuOfourBranch:'OUR BRANCHES',
        menuNews:'NEWS & EVENT',
        menuGallery:'GALLERY',
        menuFaq:'FAQ',
        faqdtls:'Frequently asked questions',
        menuContact:'CONTACT',
        btnBuyonline:'Buy Online',
        btnLogin:'Login',
        btnClaim:'Claim',
        btnGrivence:'Grievance',
        whtclitest:'What Client Says About Us',
        latneweveTitle:'Latest News & Event',

        menubuyonlinesubmenumotor:'Motor',
        menubuyonlinesubmenumotorQuoteSubmenu:'New Quote',
        menubuyonlinesubmenumotorRenewSubmenu:'Renew',


        menubuyonlinesubmenutravel:'Travel',
        menubuyonlinesubmenupaccident:'Personal Accident',
        menubuyonlinesubmenupcompass:'Compass',
        menubuyonlinesubmenuprquote:'Retrieve Quote',
        menubuyonlinesubmenupolicySearch:'Policy Search',

        menuclaimmotor:'Motor Intimation',
       
         //header

         //sliderTxt
         sliInsTxt:'Insurance to protect you better',
         sliBtnlearn:'Learn More',
         slidBtnContx:'Contact Us',
         //sliderTxt 

         //HomeOurProduct
         breadAbout:'About',
         headHomeOPrd:'OUR PRODUCTS',
         homePrdMins:'Marine Insurance',
         homePrdMinsDtls:' All Risks and Limited Risk Covers for exports and Imports by sea air and land.',
         homePrjins:'Project Insurance',
         homePrjinsDtls:' Contractors’ All Risks Policy and Erection All Risks Policy for civil and mechanical contractors and all ...',
         homeGenins:'General Insurance',
         homeGeninsDtls:'Legal liability of insured towards damage to the third party in respect of accidental death/bodily injury...',
         homePropins:'Property Insurance',
         homePropinsDtls:' For Factories, Warehouses, Business Establishments and contents therein against physical loss or damage...',
         homeFpins:'Fire And Perils Policy',
         homeFpinsDtls:'For Factories, Warehouses, Offices, Residences and contents therein against physical loss or damage...',
         homeBusins:'Business Insurance',
         homeBusinsDtls:'Legal liability covering the acts of Omission of Professional like, Brokers, Doctors, Lawyers...',
         ourproduct:'Our Product',
         ourproductprovide:'Our provided services',
         comercial:'Commercial',
         industrial:'Industrial',
         libility:'Liability',
         other:'Others',
         wwloc:'World Wide Location',
         gal:'Gallery',
         quikcont:'Quick Contact',
         btnsent:'Send Message',
         //HomeOurProduct

         //HomeAboutUS
         prdbread:'product',
         prdOurbread:'Our Product',
         homeSecAbttitle:'ABOUT US',
         homeSecAbtsubhead:'We are a professional and creative company and we offer you a trusty insurance on your veicle.',
         homeSecAbtdesc:'New India Assurance Co Ltd is a multinational general insurance Government of India Public Sector Undertaking company operating in 23 Countries and Head Quartered at Mumbai in India The Global business stands at US$ 4.6 Billion completed in the last financial year 2021-2022.',
         homeSecAbtdques:'Do you have any question?',
         cTitle:'THE NEW INDIA ASSURANCE COMPANY LIMITED',
        //HomeAboutUs

        //Homewhychoose
        homeWhyChos:'WHY CHOOSE US',
        homeWCdoyouhave:'Do you have any question?',
        homeWCdoyouhaveDtls:'We help you discover any protection inclusions that are ideal for you.',
        homeWC247:'24/7 Awesome Support',
        homeWC247Dtls:'We help you discover any protection inclusions that are ideal for you.',
        homeWCsavemon:'Save Money',
        homeWCsavemonDtls:'We help you discover any protection inclusions that are ideal for you.',
        homeWCflexplan:'Flexible Plan',
        homeWCflexplanDtls:'We help you discover any protection inclusions that are ideal for you.',
        homeWCTcom:'Trusted Company',
        homeWCTcomDtls:'We help you discover any protection inclusions that are ideal for you.',
        homeWCSafeInve:'Safe Investment',
        homeWCSafeInveDtls:'We help you discover any protection inclusions that are ideal for you.',
        //HomeWhychoose


        //Home page Data
        nStTCYF:'Need Someone to Take Care Your Future',
        nStTCyf2nd:'When you re equipped to grow, we realize it is now no longer continually clean to discover the proper markets for your enterprise in different elements of the world.',
        makeAppoint:'Make Appointment',
        cFormMail:'Your Email',
        cFormName:'Your Name',
        cFormCno:'Contact No.',
        cFormSub:'Your Subject',
        cFormMess:'Your Message',
        cFormTell:'Tell us a few words',
        phone:'Phone',
        fax:'Fax',

         //footer
         news:'News',
         newsblog:'News & Blog',
         offOur:'Our Office',
         office:'Office',
         headAbtUs:'ABOUT US',
        ftabtTxt:'New India Assurance Co Ltd is a multinational general insurance Government of India Public Sector Undertaking company operating in 23 Countries and Head Quartered',
        headComp:'COMPANY',
        headQuiclLink:'QUICK LINK',
        headNews:'NEWS LETTER',
        ftsubTxt:'Subscribe to our newsletter',
        ftSubPlacho:'Subscribe with us',
        ftsubBtn:'Subscribe'
         //footer
    },
    ar:{
        //header
        menuen:'EN',
        menuar:'العربية',
        menuHome:'الصفحة الرئيسية',
        menuAbout:'معلومات عنا',
        submenuAbout:'معلومات عنا',
        submenuAbtmsgCeo:'رسالة رئيس العمليات',
        submentAbtTeams:'',
        submenutAbtFinan:'التقارير المالية',
        submenutAbtTender:'مناقصة',
        menuProduct:'منتجاتنا',
        testinmonital:'شهادة',
        prdAbtBtn:'معلومات عنا',
        prdProposalBtn:'استمارة طلب تأمين',
        prdClaimBtn:'نموذج المطالبة',
        whtclitest:'ماذا يقول العملاء عنا',
        latneweveTitle:'أحدث الأخبار والأحداث',
        submenutPrdprivate:'خصوصي',
        submenutPrdcommercial:'تجاري',
        submenutPrdindustrial:'صناعي',
        submenutPrdlibility:'المسؤولية',
        faqdtls:'الأسئلة الشائعة',
        submenutPrdOthers:'أخرى',
        menuOffice:'مكاتبنا',
        submenuofGcc:'مكاتبنا في دول مجلس التعاون',
        submenuOfWwl:'موقع عالمي',
        submenuOfourBranch:'',
        menuNews:'أخبار و أحداث',
        menuGallery:'المعرض',
        menuFaq:'اسئلة متكررة',
        menuContact:'اتصال',
        btnBuyonline:'شراء الوثيقة',
        btnLogin:'تسجيل الدخول',
        btnClaim:'مطالبة',
        btnGrivence:'التظلم',



        menubuyonlinesubmenumotor:'محرك',
        menubuyonlinesubmenumotorQuoteSubmenu:'اقتباس جديد',
        menubuyonlinesubmenumotorRenewSubmenu:'تجديد',


        menubuyonlinesubmenutravel:'يسافر',
        menubuyonlinesubmenupaccident:'حادث شخصي',
        menubuyonlinesubmenupcompass:'بوصلة',
        menubuyonlinesubmenuprquote:'استرداد الاقتباس',
        menubuyonlinesubmenupolicySearch:'بحث السياسة',

        menuclaimmotor:'التلميح الحركي',
        

         //header

           //HomeOurProduct
           prdbread:'منتج',
           breadAbout:'عن',
           homebread:'بيت',
           headHomeOPrd:'منتجاتنا',
           homePrdMins:'التأمين البحري',
           homePrdMinsDtls:'تغطية جميع المخاطر والمخاطر المحدودة للصادرات والواردات عن طريق البحر والجو والبر.',
           homePrjins:'تأمين المشروع',
           homePrjinsDtls:'سياسة جميع مخاطر المقاولين وسياسة جميع مخاطر التشييد للمقاولين المدنيين والميكانيكيين وجميع ...',
           homeGenins:'تأمين عام',
           homeGeninsDtls:'المسؤولية القانونية للمؤمن له تجاه الضرر الذي يلحق بالطرف الثالث فيما يتعلق بالوفاة العرضية / الإصابة الجسدية...',
           homePropins:'تأمين الملكية',
           homePropinsDtls:'للمصانع والمستودعات والمؤسسات التجارية ومحتوياتها ضد الخسارة أو الضرر المادي...',
           homeFpins:'سياسة الحرائق والمخاطر',
           homeFpinsDtls:'للمصانع والمستودعات والمكاتب والمساكن ومحتوياتها ضد الخسائر أو الأضرار المادية...',
           homeBusins:'تأمين عمل',
           homeBusinsDtls:'المسؤولية القانونية التي تغطي أعمال إغفال المهنيين مثل الوسطاء والأطباء والمحامين ...',
          
           ourproduct:'منتجنا',
           ourproductprovide:'الخدمات المقدمة لدينا',
           comercial:'تجاري',
           industrial:'صناعي',
           libility:'مسئولية قانونية',
           other:'آحرون',
           wwloc:'الموقع على مستوى العالم',
           gal:'معرض',
           quikcont:'الاتصال السريع',
           //HomeOurProduct

          //HomeAboutUS
         homeSecAbttitle:'معلومات عنا',
         homeSecAbtsubhead:'نحن شركة محترفة ومبتكرة ونقدم لك تأمينًا مضمونًا على سيارتك.',
         homeSecAbtdesc:'شركة New India Assurance Co Ltd هي شركة تأمين عام متعددة الجنسيات تابعة لحكومة الهند والقطاع العام تعمل في 23 دولة ويقع مقرها الرئيسي في مومباي في الهند. تبلغ قيمة الأعمال العالمية 4.6 مليار دولار أمريكي مكتملة في السنة المالية الأخيرة 2021-2022.',
         homeSecAbtdques:'هل لديك اي سؤال؟',
         btnsent:'أرسل رسالة',
         cTitle:'شركة التأمين الهندية الجديدة المحدودة',
        //HomeAboutUs

         //Homewhychoose
         news:'أخبار',
         newsblog:'أخبار ومدونة',
         office:'مكتب',
         offOur:'مكتبنا',
         prdOurbread:'منتجنا',
         homeWhyChos:'لماذا أخترتنا',
         homeWCdoyouhave:'هل لديك اي سؤال؟',
         homeWCdoyouhaveDtls:'نحن نساعدك على اكتشاف أي إضافات حماية مثالية بالنسبة لك.',
         homeWC247:'دعم رائع على مدار 24 ساعة طوال أيام الأسبوع',
         homeWC247Dtls:'نحن نساعدك على اكتشاف أي إضافات حماية مثالية بالنسبة لك.',
         homeWCsavemon:'توفير المال',
         homeWCsavemonDtls:'نحن نساعدك على اكتشاف أي إضافات حماية مثالية بالنسبة لك.',
         homeWCflexplan:'خطة مرنة',
         homeWCflexplanDtls:'نحن نساعدك على اكتشاف أي إضافات حماية مثالية بالنسبة لك.',
         homeWCTcom:'شركة موثوقة',
         homeWCTcomDtls:'نحن نساعدك على اكتشاف أي إضافات حماية مثالية بالنسبة لك.',
         homeWCSafeInve:'الاستثمار الآمن',
         homeWCSafeInveDtls:'نحن نساعدك على اكتشاف أي إضافات حماية مثالية بالنسبة لك. ',
         //HomeWhychoose

          //sliderTxt
          sliInsTxt:'التأمين لحمايتك بشكل أفضل',
          sliBtnlearn:' معلومات اكثر',
          slidBtnContx:'تواصل معنا',
          //sliderTxt 


          //Home page Data
          nStTCYF:'بحاجة إلى شخص ما لرعاية مستقبلك',
          nStTCyf2nd:'عندما تصبح جاهزًا للنمو، ندرك أنه لم يعد من السهل دائمًا اكتشاف الأسواق المناسبة لمؤسستك في أجزاء مختلفة من العالم.',
          makeAppoint:'تحديد موعد',
          cFormMail:'البريد الإلكتروني الخاص بك',
          cFormName:'اسمك',
          cFormCno:'رقم الاتصال',
          cFormSub:'الموضوع الخاص بك',
          cFormMess:'رسالتك',
          cFormTell:'أخبرنا ببضع كلمات',
          phone:'هاتف',
          fax:'فاكس',

         //footer
         headAbtUs:'معلومات عنا',
         ftabtTxt:'شركة نيو إنديا للتأمين المحدودة هي شركة تأمين عام متعددة الجنسيات تابعة لحكومة الهند والقطاع العام تعمل في 23 دولة ومقرها الرئيسي',
         headComp:'شركة',
         headQuiclLink:'رابط سريع',
         headNews:'رسالة إخبارية',
         ftsubTxt:'اشترك في نشرتنا الإخبارية',
         ftSubPlacho:'اشترك معنا',
         ftsubBtn:'يشترك'
          //footer

    }
}