import React from 'react'
import { config } from '../../config/rest'

export const NewsData = (props) => {
    console.log(props.data);
  return (
    <section className="pb-0">
      
    <div className="container" >
        <div className="row about-style2">
            <div className="col-lg-4 mb-1-9 mb-lg-0 wow fadeIn" data-wow-delay="200ms">
                {props.data.attributes.image.data &&
                <div className="pe-xl-1-9 mb-md-2-9 position-relative">
                    <div className="text-end about-img1 position-relative image-hover">
                        <img src={config.IMG_URL+props.data.attributes.image.data.attributes.url} className="rounded" alt="..."/>
                       
                    </div> 
                </div>
                }
            </div>
            <div className="col-lg-8 wow fadeIn" data-wow-delay="400ms">
                <div className="ps-xl-2-3">
                <h3 className="h4 mb-4" >
                                        <p href="#" dangerouslySetInnerHTML={{ __html: props.data.attributes.title }}></p>
                                    </h3>
                   <span className="card-date" style={{fontWeight:'bold'}}>Date :{props.data.attributes.date}</span>
                    <p className="mb-1-9   text-secondary lh34 " 
                    dangerouslySetInnerHTML={{ __html: props.data.attributes.details }}/>
                    
                  
                    
                </div>
            </div>
           
        </div>

     
    </div>
      

    <div className="d-inline-block p-3 border border-width-2 border-primary-color bottom-10 right-10 ani-move position-absolute rounded-circle d-none d-sm-block"></div>
</section>
  )
}
