import React, { useState,useEffect } from 'react';
import { config } from '../../config/rest';
import {Link} from 'react-router-dom'
import { useSelector,useDispatch } from "react-redux";
import { Lang } from '../../component/Language/Lang';



export const Content = (props) => {
console.log('dfkjdsfkjdfkd',props.newsData);
const langData=useSelector(state=>state.cart);
useEffect(() => {
  window.scrollTo({
    top: 100,
    behavior: 'smooth',
  })
const rootHtml = document.getElementById("root-html");
  if (rootHtml) {
   rootHtml.setAttribute("dir", langData.lang==='ar' ? "rtl" : "ltr");
   rootHtml.setAttribute("lang", langData.lang ? "rtl" : "ltr");
 }
 
 
},[langData]);
  return (
    <section>
      <div className="container">
        <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
          <span> {Lang[langData.lang].latneweveTitle} </span>
          <h2 className="h1 mb-0">{Lang[langData.lang].latneweveTitle}</h2>
        </div>
        <div className="row about-style">
        {props.news.sort((a, b) => b.attributes.date > a.attributes.date ? 1 : -1)
       . map(function(data,index) {
            return(
                    <div className="col-md-6 col-lg-4 mt-6 wow fadeIn" data-wow-delay="200ms" key={index}>
                        <article className="card card-style9">
                            <div className="card-body newsEv">
                                <span className="card-date">{data.attributes.date}</span>
                                  <h3 className="h4 mb-4" style={{minHeight:'35px'}}>
                                        <p dangerouslySetInnerHTML={{ __html: data.attributes.title }}></p>
                                    </h3>

                                {data.attributes.image.data && 
                                <div className="image-box">
                                    <img src={config.IMG_URL+data.attributes.image.data.attributes.url} 
                                    className="rounded" alt="..." 
                                    style={{minHeight: "240px", objectFit: "contain:"}}/>
                                </div>
                                }
                                <Link 
                                to="/NewsDetails/newsData" state={{newsDetails:data}}
                                 className="border-bottom display-30 font-weight-600 text-dark">Read More <i className="ti-arrow-right align-middle display-31"></i></Link>
                            </div>
                        </article>
                    </div>
            )})}


        </div>
      </div>
    </section>
  )
}
