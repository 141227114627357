import React from 'react'

export const Office = (props) => {
  return (
    <div className="row about-style mb-2-9 mb-lg-6">
                <div classNameName="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
                <table 
                className='table mb-0'>
<tbody>
<tr>
<th width="9%">Location</th>
<th width="25%">Officer – In – Charge</th>
<th width="15%">Post</th>
<th width="23%">Off: Phone</th>
<th width="11%">GSM</th>
</tr>{props.ourOfficeData.map(function(data,index) {
  return(
    <>
<tr key={index}>
<td rowspan="2">{data.location}</td>
<td className='borderNone'>{data.OfficeinCharge}</td>
<td className='borderNone'>{data.post}</td>
<td className='borderNone'>{data.phone}</td>
<td className='borderNone'>{data.gsm}</td>
</tr>
<tr>
<td></td>
<td></td>
<td></td>
<td></td>
</tr>
</>
  )})}

</tbody>
</table>
                  </div>
  </div>
  )
}
