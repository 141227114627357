import React,{useEffect, useState} from 'react'
import { BreadCumb } from '../component/BreadCumb'
import { Content } from './Office/Content'
import { useParams } from "react-router-dom";
import { GccLocation } from './Office/GccLocation';
import { WorldWideWeb } from './Office/WorldWideWeb';
import { OurBranch } from './Office/OurBranch';
import { Lang } from '../component/Language/Lang';
import { useSelector,useDispatch } from "react-redux";


export const Office = () => {
  const langData=useSelector(state=>state.cart);
  let { titleOffice } = useParams(); 
  const[titlename,setName]=useState(titleOffice);
  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    })
    console.log(`/something/${titleOffice}`);
    setName(titleOffice);
},[titleOffice]);
  return (
<>
<BreadCumb bgImage={"url('/assets/img/banner/office.jpeg')"} routeLink={"office"} 
mainRoute={Lang[langData.lang].homebread} 
    route={Lang[langData.lang].office}  title={Lang[langData.lang].offOur}/>

{titleOffice==='OurOffice'?
    <Content/>:
    titleOffice==='GccLocation'?
    <GccLocation/>:
    titleOffice==='WorldWideLocation'?
    <WorldWideWeb/>:
    titleOffice==='Branches'?
    <OurBranch/>:<></>}
</>
  )
}
