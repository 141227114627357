import axios from "axios";
import { config } from "./rest";

export const axiosInstance = axios.create({
  baseURL: config.API_URL,
  headers: {
    Authorization: `Bearer 3dcfa966fcdf4ad74b21952b82f4b425a3d2419959a8e18e70f15d61c31fdd0bd50214a8afa1f03ba9486f574cd9e0c9a6dc0035fd1877cde54c644b26e2fe45a0b52a62e6b4eda9efa1ce2b0b9fa85e2d414429d73777ac9de25efe5d0f6fe025eb311b31fa2a52ce235a49057fe8ca2d15aaf1b6470b5aa29368c56d950ceb`
  }
});


//3dcfa966fcdf4ad74b21952b82f4b425a3d2419959a8e18e70f15d61c31fdd0bd50214a8afa1f03ba9486f574cd9e0c9a6dc0035fd1877cde54c644b26e2fe45a0b52a62e6b4eda9efa1ce2b0b9fa85e2d414429d73777ac9de25efe5d0f6fe025eb311b31fa2a52ce235a49057fe8ca2d15aaf1b6470b5aa29368c56d950ceb
//mylocal
//df4d1c78b4ffa868cca4a423f0260ff81ed6c7be60b03e139313dda99514a0c776069792deaead583f8c668b529b4163ad9966770492512a361a8701e5b9cc4ef03a8caeea790f7f629ac16b89f1317623e4f70d7dfbf090ec7d758a3717186aa85c8306161516cca16209a3d4fe440ce2f91e310adc1088af04d186b2e00dfa
