import React from 'react'
import { config } from '../../config/rest';
import {Link} from 'react-router-dom'
import moment from 'moment'

export const Tender = (props) => {
         console.log(props.tenderData);
  return (//ceoMessData
    <section className="pb-0">
         
        
    <div className="container" >
        <div className="row about-style2">
            
            <div className="col-lg-12 wow fadeIn" data-wow-delay="400ms">
                <div className="ps-xl-2-3">
                <table 
                className='table mb-0'>
<tbody>
<tr>
<th width="9%">Title</th>
<th width="25%">Created Date</th>
<th width="15%">View</th>
<th>Download</th>
</tr>
{props.tenderData.map(function(data,index) {
         const tenDerData=data.attributes.tenderFile.data!==null?config.IMG_URL+data.attributes.tenderFile.data.attributes.url:'';

        return (
            <>
            
                <tr key={index}>
            <td rowspan="2">{data.attributes.title}</td>
            <td className='borderNone'>{moment(data.attributes.publishedAt).format('DD MMM, YYYY')}</td>
            <td className='borderNone'><Link 
                                to="/TenderDetails/tenderData" state={{newsDetails:data}} 
                                 className="border-bottom display-30 font-weight-600 text-dark">
                                    View Details<i className="ti-arrow-right align-middle display-31"></i>
                     </Link></td>
        <td> <a href={tenDerData}  target="_blank" className="butn  sm"><span>Download Tender</span></a></td>
           
            </tr>
            <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            </>
            )})}

            </tbody>
            </table>
                  
                </div> 
            </div>
        </div>
        </div>
       
      
        </section>
  )
}
