import React ,{useEffect,useState}from 'react';
import { BreadCumb } from '../component/BreadCumb'

import { listNewsEventData, listNewsEventDataar } from '../service/apiService';
import { Content } from './Gallery/Content';
import { useSelector,useDispatch } from "react-redux";
import { Lang } from '../component/Language/Lang';

export const Gallery = () => {
  const [newsContact,setnewsContact]=useState([]);
  const langData=useSelector(state=>state.cart);
  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    }) 
    getNewsProduct(); 
  },[]);

  const getNewsProduct=async()=>{
    const newsData=langData.lang==='ar'?await listNewsEventData():await listNewsEventDataar();
    console.log(newsData);
    setnewsContact(newsData.data.data);
  }

  return (
   <>
     <BreadCumb bgImage={"url('assets/img/banner/newsevents.jpg')"} 
     routeLink={"Gallery"} mainRoute={"Home"} 
    route={"Gallery"} title={"Gallery"}/>
    <Content newsData={newsContact}/>
   </>
  )
}
