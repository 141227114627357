export const config={
    /*API_URL:"http://localhost:1337/api",
    AUTH_API_URL:"http://localhost:1337/api/",  
    NOTIFICATION_REQUEST_ERROR:'some error in network',
    IMG_URL:"http://localhost:1337",*/
    
    API_URL:"https://strapi-174970-0.cloudclusters.net/api",
    AUTH_API_URL:"https://strapi-174970-0.cloudclusters.net/api/",  
    NOTIFICATION_REQUEST_ERROR:'some error in network',
    IMG_URL:"https://strapi-174970-0.cloudclusters.net",
}